<template>
  <div class="check_xm" v-loading="loading">
    <div class="close">
      <i class="el-icon-circle-close" @click="closeAction"></i>
    </div>
    <div class="not_complate">
      <h3>未完成的功能</h3>
      <div class="operate">
        <div
          class="checkBox"
          v-for="(item, index) in notcomplate"
          :key="item.id"
        >
          <el-checkbox
            :checked="item.state"
            @change="changeAction(item, index)"
            >{{ item.gongneng_name }}</el-checkbox
          >
        </div>
      </div>
    </div>
    <div class="complate">
      <h3>已完成的功能</h3>
      <div class="operate">
        <div class="checkBox" v-for="(item, index) in complate" :key="item.id">
          <el-checkbox
            :checked="item.state"
            @change="changeAction(item, index)"
            >{{ item.gongneng_name }}</el-checkbox
          >
        </div>
      </div>
    </div>
    <div class="complate">
      <h3>讲师评论</h3>
      <div class="pinglun">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="pinglun"
        >
        </el-input>
      </div>
      <div class="card">
        <el-card class="box-card" v-for="item, index in pingluns" :key="item.id">
          <div slot="header" class="clearfix">
            <span>{{item.date}}</span>
            <i class="el-icon-delete" @click="deletePL(item.id, index)"></i>
          </div>
          <div class="text item">
            {{item.pinglun}}
          </div>
        </el-card>
      </div>
    </div>
    <div class="btn">
      <el-button type="primary" size="mini" @click="saveUpdate"
        >保存修改</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckXM",
  data() {
    return {
      notcomplate: [],
      complate: [],
      loading: false,
      xms: [],
      pinglun: "",
      xm_id: null,
      xs_id: null,
      pingluns:[]
    };
  },
  async created() {
    this.xs_id = this.$route.query.id;
    this.xm_id = this.$route.query.xm_id;
    this.$datas.meta = { id: this.$route.query.id };
    let res = await this.$datas.getStuByID;
    let xms = res.data.bjc;
    this.xms = xms;
    for (let i in xms) {
      if (xms[i].state) {
        this.complate.push(xms[i]);
      } else {
        this.notcomplate.push(xms[i]);
      }
    }
    this.$datas.meta = {xm_id:this.$route.query.xm_id, xs_id:this.$route.query.id};
    res = await this.$datas.getPingLun;
    this.pingluns = res.data.data;
  },
  computed: {
    total() {
      let val = 0;
      for (let i in this.xms) {
        val += this.xms[i].quanzhong;
      }
      return val;
    },
    complateVal() {
      let val = 0;
      for (let i in this.complate) {
        val += this.complate[i].quanzhong;
      }
      return val;
    },
  },
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
    changeAction(item, index) {
      if (item.state == false) {
        item.state = true;
        this.notcomplate.splice(index, 1);
        this.complate.splice(index, 0, item);
      } else {
        item.state = false;
        this.complate.splice(index, 1);
        this.notcomplate.splice(index, 0, item);
      }
    },
    async saveUpdate() {
      this.loading = true;
      //开始保存
      for (let i in this.complate) {
        let item = this.complate[i];
        this.$datas.meta = { ...item };
        await this.$datas.updateBjCheck;
      }
      for (let i in this.notcomplate) {
        let item = this.notcomplate[i];
        this.$datas.meta = { ...item };
        await this.$datas.updateBjCheck;
      }
      //更新该学生的完成进度
      let jibie = this.complateVal / this.total;
      jibie *= 100;
      this.$datas.meta = { id: this.$route.query.id, jibie: jibie.toFixed(2) };
      await this.$datas.updateStuParse;
      if (this.pinglun) {
        this.$datas.meta = {
          xm_id: this.xm_id,
          xs_id: this.xs_id,
          pinglun: this.pinglun,
        };
        let pl = await this.$datas.addXMPL;
        this.pingluns.unshift(pl.data.data);
      }
      this.$message("数据已更新");
      this.loading = false;
    },
    async deletePL(id, index){
      //删除评论 
      this.$datas.meta = {id};
      await this.$datas.deletPingLun;
      this.pingluns.splice(index, 1);
    }
  },
};
</script>
<style lang="less" scoped>
.check_xm {
  .close {
    padding: 10px 20px;
    text-align: right;

    i {
      font-size: 25px;
      cursor: pointer;
      color: #555;

      &:hover {
        color: #aaff;
      }
    }
  }
  h3 {
    font-size: 14px;
    color: #555;
    font-weight: 400;
  }
  .operate {
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .checkBox {
      width: 180px;
      margin: 5px;
      overflow: hidden;
    }
  }
  .pinglun {
    width: 70%;
    margin: 10px 20px;
  }
  .clearfix {
    display: flex;
    justify-content: space-between;
  }
  .card {
    width: 70%;
    margin: 10px 20px;
  }
  .btn {
    margin: 30px 0;
  }
  .el-icon-delete{
    color: #409eff;
    cursor: pointer;
  }
  .el-card  {
    margin: 5px 0;
  }
}
</style>
